<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item v-if="isNew" :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item v-if="isNew" :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')">

			<app-input-suggest :disabled="!isNew" :locked="!isNew" label="Participant" placeholder="Search participant..." v-model="model.participant" api="convention/schedule/contest/participant" :params="{ contest: $route.params.contest } " :validation="$v.model.participant" />
			<app-input-select :disabled="!isNew" :locked="!isNew" label="Prize" placeholder="Select prize" :options="prizes" v-model="model.prize" :validation="$v.model.prize" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			references: {
				prizes: []
			},
			model: {
				id: false,
				prize: 0,
				participant: 0
			}
		}

	},

	validations: {
		model: {
			participant: {
				required
			},
			prize: {
				required
			}
		}
	},

	computed: {

		prizes: function() {

			var prizes = []

			this.$_.each(this.references.prizes, function(item) {

				prizes.push({
					value: item.id,
					text: item.name
				})

			})

			return prizes

		}

	}

}

</script>

<style scoped>

</style>